<template>
  <div class="container">
    <div class="header">
      <div class="headerTop">
        <div class="wrap clearfloat">
          <div class="fl">
            <div class="headerLogo fl">
              <a href="/"><img src="../assets/img/logo_ghatg_xny.png" alt=""></a>
            </div>
          </div>
          <div class="fr">
            <div class="headerTopInner headerTopSearch">
              <el-input placeholder="请输入关键词" v-model="searchTxt" class="input-with-select">
                <el-button slot="append" type="primary" icon="el-icon-search" @click="handleSearch"></el-button>
              </el-input>
            </div>
            <div class="headerTopIco headerTopOa">
              <a href="http://61.178.220.125:9000" target="_blank" title="办公OA"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="headerBox clearfloat">
        <div class="wrap">
          <!-- <div class="headerLogo fl">
            <a :href="`${apiName}`"><img src="../assets/img/logo_xin.png" alt=""></a>
          </div> -->
          <div class="nav_box">
            <ul class="clearfloat">
              <li :class="{'active':active == 0}">
                <div class="nav_grop">
                  <h2><a href="/">首页</a></h2>
                </div>
              </li>
              <li v-for="(item, index) in navListN" :key="index" :class="{'active':active == item.id}">
                <div
                  class="nav_grop" 
                  @mouseenter="navShow(item.id)"
                  @mouseleave="navClose()">
                  <h2><a :href="`${apiName}${item.url}`">{{item.name}}</a></h2>
                  <el-collapse-transition>
                    <div class="nav_down" v-show="navDown == item.id">
                      <div class="nav_down_wrap clearfloat" v-show="item.group_list.length != 0">
                        <div class="nav_down_sj trans"></div>
                        <p v-for="(item1, index1) in item.group_list" :key="index1">
                          <a :href="item1.url && item1.url != '' ? `${apiName}${item1.url}` : `${apiName}/list?id=${item1.id}&navName=${item.name}&groupName=${item1.name}`" v-if="item1.have_list == 0 && item1.is_link !== 1">{{item1.name}}</a>
                          <a :href="item1.url && item1.url != '' ? `${apiName}${item1.url}` : `${apiName}/detail?id=${item1.id}&navName=${item.name}&groupName=${item1.name}&noList=1`" v-if="item1.have_list == 1 && item1.is_link !== 1">{{item1.name}}</a>
                          <a :href="`${apiName}${item1.url}`" target="_blank" v-if="item.name == '核心成员'">{{item1.name}}</a>
                          <a :href="item1.link_url" target="_blank" v-if="item1.is_link === 1">{{item1.name}}</a>
                        </p>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="main mainBox" :class="{mainHome : isHome}">
      <router-view></router-view>

      <div class="footer" :class="{footerHome: isHome}">
        <div class="wrap footerBox">
          <p>
            <a href="" target="_blank">联系我们</a>
          </p>
          <p>
            版权所有-甘肃公航旅新能源集团有限公司 <span class="footerGa"><img src="../assets/img/ico_ga.png" alt=""></span> <em>甘公网安备 62010202000445号</em>
          </p>
          <p>
            陇ICP备11000302号-1
          </p>
        </div>
        <!-- <footer-home v-if="isHome"></footer-home>
        <footer-main v-if="!isHome"></footer-main> -->
      </div>
    </div>
    
  </div>
</template>

<script>
  // import footerMain from '../components/footer.vue';
  // import footerHome from '../components/footerHome.vue';
export default {
  components: {
    // footerMain,
    // footerHome
  },
  name: '',
  data(){
    return {
      isHome: true,
      searchTxt: '',
      active: 0,
      navListN: [
        {
            "id":1,
            "name":"公司概况",
            "isIndex":0,
            "url": '/company',
            "group_list":[
                {
                    "id":4,
                    "name":"公司简介",
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:41.071Z",
                    "url": '/company'
                },
                {
                    "id":2,
                    "name":"领导成员",
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": '/company?t=1'
                },
                {
                    "id":2,
                    "name":"组织架构",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": '/company?t=2'
                },
                {
                    "id":2,
                    "name":"子公司简介",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": '/company?t=3'
                },
                {
                    "id":2,
                    "name":"企业文化",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": '/company?t=4'
                },
                {
                    "id":2,
                    "name":"公司荣誉",
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z",
                    "url": '/company?t=5'
                },
            ]
        },
        {
            "id":2,
            "name":"新闻中心",
            "isIndex":0,
            "url": '/news',
            "group_list":[
                {
                    "id":7,
                    "name":"公司要闻",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:14.391Z",
                    "url": '/news'
                },
                {
                    "id":8,
                    "name":"子公司动态",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": '/news?t=1'
                },
                {
                    "id":8,
                    "name":"项目资讯",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": '/news?t=2'
                },
                {
                    "id":8,
                    "name":"通知公告",
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z",
                    "url": '/news?t=3'
                },
            ]
        },
        {
            "id":3,
            "name":"公司业务",
            "isIndex":0,
            "url": '/business',
            "group_list":[
              {
                  "id":8,
                  "name":"发电业务",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": '/business?t=0'
              },
              {
                  "id":8,
                  "name":"电池制造",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": '/business?t=1'
              },
              {
                  "id":8,
                  "name":"储能技术服务",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": '/business?t=2'
              },
              {
                  "id":8,
                  "name":"充电设施运营",
                  "nav_id":3,
                  "have_list":0,
                  "create_time":"2020-12-22T07:11:41.023Z",
                  "url": '/business?t=3'
              },
            ]
        },
        {
            "id":4,
            "name":"党建园地",
            "isIndex":0,
            "url": '/party',
            "group_list":[
                {
                    "id":14,
                    "name":"党建工作",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z",
                    "url": '/party'
                },
                {
                    "id":15,
                    "name":"纪检监察",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z",
                    "url": '/party?t=1'
                },
                {
                    "id":16,
                    "name":"群团工作",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z",
                    "url": '/party?t=2'
                },
                {
                    "id":17,
                    "name":"工会工作",
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z",
                    "url": '/party?t=3'
                },
            ]
        },
        {
            "id":5,
            "name":"人力资源",
            "isIndex":0,
            "url": '/hr',
            "group_list":[
                {
                    "id":20,
                    "name":"招贤纳士",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": '/hr'
                },
                {
                    "id":20,
                    "name":"人才引进",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": '/hr?t=1'
                },
                {
                    "id":20,
                    "name":"下载中心",
                    "nav_id":5,
                    "have_list":0,
                    "create_time":"2020-12-22T07:14:03.529Z",
                    "url": '/hr?t=2'
                },
            ]
        },
        {
            "id":6,
            "name":"联系我们",
            "isIndex":0,
            "url": '/contact',
            "group_list":[
            ]
        }
      ],
      navDown: 0,

    }
  },
  mounted(){
    this.getPath()
    if (this.$route.name == 'home') {
      this.isHome = true
    } else {
      this.isHome = false
    }
  },
  created(){

  },
  watch:{
    '$route':'getPath'
  },
  methods: {
    handleSearch() {
      var _this = this
      if (!_this.searchTxt || _this.searchTxt == '') {
        this.$message({
          message: '请输入关键词',
          type: 'warning'
        })
      } else {
        window.location.href = `/search?title=${_this.searchTxt}`
        // window.open(`/search/${_this.searchValue}`)
      }
    },
    // 二级导航展开
    navShow(d){
      let _this = this
      // _this[d] = true
      _this.navDown = d
    },
    // 二级导航收起
    navClose(){
      let _this = this
      // _this[d] = false
      _this.navDown = 0
    },
    haddleNav(index, params){
      var _this = this

      _this.active = index
      _this.$router.push(params.url)
    },
    getPath(){
      var _this = this
      console.log('_this.$route.name :>> ', _this.$route.name);
      _this.active = _this.$route.meta.index
      console.log('_this.active :>> ', _this.active);
    }
  }
}
</script>
<style>
.header {
  text-align: center;
  /* background-image: url(../assets/img/header_bg.png); */
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.headerLogo {
  margin-top: -3px;
  /* margin-bottom: 20px; */
}
.headerLogo img {
  /* width: 360px; */
  height: 70px;
  width: auto;
  vertical-align: top;
}
.headerNavList {
  padding-bottom: 18px;
}
.headerNavList li {
  float: left;
  text-align: center;
  width: 12.5%;
  position: relative;
  line-height: 20px;
  font-size: 16px;
}
.headerNavList li:last-child .line {
  display: none;
}
.headerNavTxt {
  display: inline-block;
  min-width: 45px;
  color: #000;
  letter-spacing: 4px;
  cursor: pointer;
}
.headerNavTxt:hover {
  color: #BC8F68;
}
.headerNavList .line {
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #d3d3d3;
  top: 50%;
  margin-top: -1px;
  right: -6px;
}
.headerNavList li.active .headerNavTxt {
  font-weight: bold;
  color: #BC8F68;
}
.headerNavList li.active::after {
  display: block;
  width: 46px;
  height: 18px;
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -23px;
  background-image: url(../assets/img/header_nav_ico.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.main {
  min-height: 500px;
}
.footer {
  color: #fff;
  text-align: center;
  padding: 10px 0;
  background-repeat: repeat;
  background-position: center;
  background-image: url(../assets/img/bg_footer.jpg);
  margin-top: 20px;
}
.footer.footerHome {
  background: none;
}
.footer a {
  color: #fff;
}
.footer p {
  line-height: 28px;
}
.footer p em {
  font-weight: bold;
}
.footerGa {
  margin-left: 10px;
}
.footerGa img {
  width: 20px;
  vertical-align: sub;
}
.nav_box {
    /* height: 38px; */
    /* background-color: #1970b9; */
    line-height: 38px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .nav_box ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .nav_box ul li {
    height: 50px;
    line-height: 50px;
    /* float: left;  */
    /* width: 96px;  */
    color: #fff;
    text-align: center;
    /* position: relative; */
  }
  /* .nav_box ul li a {
    display: block;
  } */
  .nav_grop {
    /* width: 82px; */
    height: 100%;
    /* margin: 5px auto 0; */
    position: relative;
  }
  .nav_box ul li h2 {
    text-align: center;
    /* line-height: 50px; */
    height: 100%;
    font-size: 19px;
    /* border-radius: 4px; */
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    overflow: hidden;
    /* border: 1px solid #1970b9; */
    position: relative;
  }
  .nav_box ul li h2 a {
    display: block;
    color: #fff;
    padding: 0 16px;
  }
  .nav_grop:hover h2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .nav_box ul li:hover h2 a {
    color: #fff;
    background-color: #004097;
    font-weight: 600;
  }
  /* .nav_box ul li:hover h2 a::after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background-color: #FABF01;
  } */
  .nav_down {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50px;
    z-index: 55;
    text-align: center;
    line-height: 36px;
    padding-top: 10px !important;
  }
  .nav_down_wrap {
    margin: 0 auto;
    /* border: 1px solid rgb(35, 96, 176); */
    border-top: none;
    /* background-color: #fff; */
    box-sizing: border-box;
    background-color: #004097;
  }
  .nav_down_line {
    display: inline-block;
    width: 1px;
    height: 24px;
    vertical-align: middle;
    background-color: #fff;
    margin: 0 12px;
  }
  .nav_down p {
    line-height: 40px;
    text-align: center;
    font-size: 14px;
  }
  .nav_down p a {
    display: block;
    color: #fff;
    vertical-align: middle;
  }
  .nav_down p a:hover {
    /* color: #1970b9; */
    background-color: #1f87e8;
  }
  .header_hd {
    height: 34px;
    line-height: 34px;
    /* background-image: url("../assets/img/st_03.jpg"); */
    background-repeat: repeat-x;
    background-size: auto 100%;
    padding: 0 25px;
    font-size: 12px;
  }
  .header_top {
  }
  .mobile {
    display: block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }

  .mobile:hover {
    color: #d60f0f;
  }
  .user_input {
    display: inline-block;
    width: 120px;
  }
  .user_l {
    display: inline-block;
    margin-left: 10px;
  }
  .user_sure {
    margin: 0 4px 0 6px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px solid #b6b6b6;
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
    background-color: #e9e9e9;
    border-radius: 2px;
  }
  .user_sure:hover {
    background-color: #dddddd;
  }
  .user_forget:hover {
    color: #d60f0f;
    text-decoration: underline;
  }
  .mail_dsz {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    color: #d60f0f;
    margin-left: 30px;
  }
  .mail_dsz:hover {
    border-color: #d60f0f;
  }
  .user_wrap {
    display: inline-block;
  }
  .headerTop {
    padding: 20px 0;
  }
  .headerTopInner {
    display: inline-block;
  }
  .headerTopSearch {
    width: 320px;
    margin-top: 26px;
  }
  .headerTopJt a {
    color: #666;
  }
  .headerTopJt a:hover {
    color: #004097;
  }
  .headerBox {
    background-color: #1f87e8;
    color: #fff;
  }
  .headerTopSearch .el-input-group__append,
  .headerTopSearch .el-input-group__prepend {
    background-color: #1f87e8;
  }
  .headerTopSearch .el-input-group__append button.el-button {
    color: #fff;
    font-size: 20px;
  }
  .nav_down_sj {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #004097;
    border-width: 5px;
    left: 50%;
    margin-left: -5px;
    top: 0px;
  }
  .headerTopIco {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 26px;
  }
  .headerTopIco a {
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    background-image: url(../assets/img/ico_oa.png);
  }
  .headerTopIco a:hover {
    background-image: url(../assets/img/ico_oa02.png);
  }
</style>